import React from "react"

import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import amazon_business from "../banner/static/amazon_business.webp"
import * as classes from "./amazon_training.module.scss"
import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})
export default function AmazonTraining() {
  return (
    <React.Fragment>
      <MainHeader />
      <Banner title={"Amazon Training"} backgroundImage={amazon_business} />
      <div className={classes.text_div}>
        <h2>Amazon Training</h2>
        <p>
          Want to sell on Amazon? Learn from the best in business. Our trainers
          will guide you how to be a pro amazon FBA business. You think of a
          product, and we will guide you on how to make it a reality.
          <br />
          <b>Amazon FBA business</b> is a setup where you, as the seller, get to
          choose what product you want to sell, find a supplier, order the
          products, package, and ship. Everything is housed in one of Amazon’s
          many Fulfillment Centers, and then they take care of the rest.
        </p>
        <a
          href="https://mastrainers.com/downloads/amazon_business_training.pdf"
          className={classes.button}
          target="blank"
        >
          Course Details
        </a>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
